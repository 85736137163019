import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/Layout/index.js";
import { Link } from "gatsby";
import SEO from "../components/SEO";
import Wide from "../components-styled/Wide";
import { Flex, Box, Text } from "theme-ui";
import Announcements from "../components/Announcements";
import PictureSet from "../components/PictureSet";
import imgSet01 from "../imgsets/imgset01";
import number01 from "../img/svg/number01.svg";
import number02 from "../img/svg/number02.svg";
import number03 from "../img/svg/number03.svg";
import number04 from "../img/svg/number04.svg";
import number05 from "../img/svg/number05.svg";
import number06 from "../img/svg/number06.svg";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Ziel" keywords={[]} mdxType="SEO" />
    <Wide mdxType="Wide">
      <PictureSet imgSet={imgSet01} mdxType="PictureSet" />
    </Wide>
    <Announcements mdxType="Announcements" />
    <h1>{`Willkommen`}</h1>
    <p>{`Liebe Gründerinnen, Gründer und Gründungsinteressierte,`}</p>
    <p>{`wir freuen uns, dass ihr uns besucht. Auf unserer Webseite findet ihr folgende Informationen:`}</p>
    <Flex style={{
      alignItems: "center",
      paddingBottom: "24px"
    }} mdxType="Flex">
  <img src={number01} style={{
        flex: "0 0 auto",
        width: "76px",
        paddingRight: "16px",
        paddingBottom: "14px"
      }} />
      <p><a parentName="p" {...{
          "href": "/veranstaltungen"
        }}><strong parentName="a">{`Veranstaltungen`}</strong></a>{`, die wir für Gründerinnen und Gründer
anbieten und die für euch sicher interessant sind.`}</p>
    </Flex>
    <Flex style={{
      alignItems: "center",
      paddingBottom: "24px"
    }} mdxType="Flex">
  <img src={number02} style={{
        flex: "0 0 auto",
        width: "76px",
        paddingRight: "16px",
        paddingBottom: "14px"
      }} />
      <p>{`Den `}<a parentName="p" {...{
          "href": "/koelner-weg"
        }}><strong parentName="a">{`»Kölner Weg«`}</strong></a>{` — das ist der prinzipielle Weg, der bei einer Gründung durchlaufen wird. Entlang des »Kölner Wegs« findet ihr Veranstaltungen und Angebote von unseren Mitgliedern, die für euch hilfreich sein können.`}</p>
    </Flex>
    <Flex style={{
      alignItems: "center",
      paddingBottom: "24px"
    }} mdxType="Flex">
  <img src={number03} style={{
        flex: "0 0 auto",
        width: "76px",
        paddingRight: "16px",
        paddingBottom: "14px"
      }} />
      <p>{`Einen `}<a parentName="p" {...{
          "href": "/blog"
        }}><strong parentName="a">{`Blog`}</strong></a>{` mit interessanten Themen aus dem Kontext von Gründungen.`}</p>
    </Flex>
    <Flex style={{
      alignItems: "center",
      paddingBottom: "24px"
    }} mdxType="Flex">
  <img src={number04} style={{
        flex: "0 0 auto",
        width: "76px",
        paddingRight: "16px",
        paddingBottom: "14px"
      }} />
      <p>{`Viele `}<a parentName="p" {...{
          "href": "/infos"
        }}><strong parentName="a">{`Infos`}</strong></a>{` und Links auf weitere nützliche Quellen sowie die Möglichkeit für ein `}<a parentName="p" {...{
          "href": "/matching"
        }}><strong parentName="a">{`Matching`}</strong></a>{` im Kontext eurer Gründung.`}</p>
    </Flex>
    <Flex style={{
      alignItems: "center",
      paddingBottom: "24px"
    }} mdxType="Flex">
  <img src={number05} style={{
        flex: "0 0 auto",
        width: "76px",
        paddingRight: "16px",
        paddingBottom: "14px"
      }} />
      <p>{`Hinweise auf `}<a parentName="p" {...{
          "href": "/business-planner"
        }}><strong parentName="a">{`Tools`}</strong></a>{`, die wir euch zum Erstellen eines Business Models, eines Business Plans oder für die Marktanalyse empfehlen.`}</p>
    </Flex>
    <Flex style={{
      alignItems: "center",
      paddingBottom: "24px"
    }} mdxType="Flex">
  <img src={number06} style={{
        flex: "0 0 auto",
        width: "76px",
        paddingRight: "16px",
        paddingBottom: "14px"
      }} />
      <p>{`Mehr zu uns, unserem wichtigen Programm `}<a parentName="p" {...{
          "href": "/fit-for-invest"
        }}><strong parentName="a">{`»Fit for Invest«`}</strong></a>{`, unseren `}<a parentName="p" {...{
          "href": "/services"
        }}><strong parentName="a">{`Gründungsservices`}</strong></a>{` und `}<a parentName="p" {...{
          "href": "/hgnc"
        }}><strong parentName="a">{`unserem Verein`}</strong></a>{` selbst.`}</p>
    </Flex>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      